import request from '@/utils/request'

// 查询学生信息分页
export function pageStudent(query) {
  return request({
    url: '/user/user/page',
    method: 'get',
    params: query
  })
}

// 禁用|启用用户
export function handleStatus(data) {
  return request({
    url: '/user/user/status',
    method: 'post',
    data
  })
}

// 审核通过用户
export function handleSh(data) {
  return request({
    url: '/user/user/edit',
    method: 'post',
    data
  })
}
